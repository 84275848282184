import { Component } from '@angular/core';

@Component({
  selector: 'app-newsletter-form-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent {
  selectedForm: string = "newsletter-form";
}
