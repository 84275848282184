import { NgModule } from '@angular/core';
import { ButtonsCreateComponent } from './buttons-create.component';

import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [

    RouterModule,
    MatRippleModule
  ],
  declarations: [ButtonsCreateComponent],
  exports: [ButtonsCreateComponent]
})
export class ButtonsCreateModule { }
