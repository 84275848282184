<form [formGroup]="newsletterForm" (ngSubmit)="onSubmit()">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="headline">Audio Newsletter</h1>

    <hypecast-admin-buttons-submit [disabled]="!newsletterForm.valid">
      Generate Podcast
      <mat-icon class="spinner" *ngIf="showLoadingIndicator">
        <mat-spinner color="accent" diameter="20">
        </mat-spinner>
      </mat-icon>
    </hypecast-admin-buttons-submit>

    </div>

  <label formArrayName="audioFiles">Intro</label>
  <div class="drop-zone" (drop)="onDrop($event, 'intro')" (dragover)="onDragOver($event)" (click)="fileInput.click()">
    <div *ngIf="introAudioFile">
      <img src="assets/icons/audioFileIcon.png"  class="file-icon-image"  onerror="this.style.display='none'"/>
    <div class="filename">{{ introAudioFile.name }}</div>
  </div>
  <ng-container *ngIf="introAudioFile; else normalView">Drag and drop audio file here to change intro</ng-container>
  <ng-template #normalView>Drag and drop files here</ng-template>
  </div>
  <input type="file" accept=".mp3, .wav" #fileInput style="display: none;" (change)="onFileInputChange($event, 'intro')">

  <div formArrayName="inputFields">
    <div *ngFor="let field of inputFields.controls; let i = index">
      <div class="spakerSelection">
        <mat-form-field class="mdc-text-field--no-label">
          <mat-label>Speaker</mat-label><br>
          <select #speakerDropdown matNativeControl (change)="onSpeakerChange($event)">
              <option value="male" [selected]="i % 2 === 0">Male</option>
              <option value="female" [selected]="i % 2 !== 0">Female</option>
          </select>
        </mat-form-field>
      </div>

      <div class="container">
        <textarea #textarea rows="8" matInput [formControlName]="i" autofocus="autofocus"
                  (paste)="handlePaste($event)"
                  (keydown)="onKeyDown($event, i)"
                  cdkTextareaAutosize
                  placeholder="Type here..."
                  #autosize="cdkTextareaAutosize"
                  >
        </textarea>
        <div>
          <ng-container *ngIf="i>0">
            <button class="remove-button" (click)="removeInputField(i)">
              <div class="center-icon">
                <mat-icon svgIcon="close" class="remove-icon"></mat-icon>
              </div>
            </button>

          </ng-container>
        </div>
      </div>
      <ng-container *ngIf="i === 0">
        <em style='font-size:20px;'>&crarr; </em> <small>Press enter to add a new textarea</small><br>
      </ng-container>
    </div>
  </div>

  <label formArrayName="audioFiles">Outro</label>
  <div class="drop-zone" (drop)="onDrop($event, 'outro')" (dragover)="onDragOver($event)" (click)="fileInput2.click()">
           <div *ngIf="outroAudioFile">
            <img src="assets/icons/audioFileIcon.png" class="file-icon-image"  onerror="this.style.display='none'"/>
            <div class="filename">{{ outroAudioFile.name }}</div>
        </div>
        <ng-container *ngIf="outroAudioFile; else normalView">Drag and drop audio file here to change outro</ng-container>
        <ng-template #normalView>Drag and drop files here</ng-template>
  </div>
  <input type="file" accept=".mp3, .wav" #fileInput2 style="display: none;" (change)="onFileInputChange($event, 'outro')">

  <div class="page-end" fxLayout="row" fxLayoutAlign="end end">
    <button class="button-margins hypecast-secondary-button flex-initial rounded-md h-12 mr-3" color="primary" mat-raised-button type="button" (click)="addInputField()">Add paragraph</button>
    <hypecast-admin-buttons-submit [disabled]="!newsletterForm.valid">
      Generate Podcast
      <mat-icon class="spinner" *ngIf="showLoadingIndicator">
        <mat-spinner color="accent" diameter="20">
        </mat-spinner>
      </mat-icon>
    </hypecast-admin-buttons-submit>
  </div>

</form>
