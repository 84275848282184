<div class="switcher">
  <mat-button-toggle-group name="fromStyle" [(ngModel)]="selectedForm" aria-label="Form Style">
    <mat-button-toggle value="newsletter-form">Newsletter Form</mat-button-toggle>
    <mat-button-toggle value="pdf-input">PDF Input Form</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div>
  <app-newsletter-form *ngIf="selectedForm === 'newsletter-form'"></app-newsletter-form>
  <app-newsletter-pdf-input *ngIf="selectedForm === 'pdf-input'"></app-newsletter-pdf-input>
</div>
