<form [formGroup]="newsletterForm" (ngSubmit)="submissionForm2 === true? onFinalSubmit() : onPdfSubmit()">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1 class="headline">Audio Newsletter</h1>
      <ng-container *ngIf="submissionForm2 === false">
        <hypecast-admin-buttons-submit [disabled]="!newsletterForm.valid || !pdfFile">
          Generate Podcast Script
          <mat-icon class="spinner" *ngIf="showLoadingIndicator === true  && completionPdfToText === false">
            <mat-spinner color="accent" diameter="20">
            </mat-spinner>
          </mat-icon>
        </hypecast-admin-buttons-submit>
      </ng-container>

        <ng-container *ngIf="submissionForm2 === true">
          <hypecast-admin-buttons-submit [disabled]="!newsletterForm.valid || !pdfFile">
            Generate Podcast
            <mat-icon class="spinner" *ngIf="showLoadingIndicator === true  && completionPdfToText === false">
              <mat-spinner color="accent" diameter="20">
              </mat-spinner>
            </mat-icon>
          </hypecast-admin-buttons-submit>
        </ng-container>
      </div>

    <!-- Intro drag and drop zone -->
    <label formArrayName="files">Intro</label>
    <div class="drop-zone" (drop)="onDrop($event, 'intro')" (dragover)="onDragOver($event)" (click)="fileInput.click()">
        <div *ngIf="introAudioFile">
            <img src="assets/icons/audioFileIcon.png" class="file-icon-image" onerror="this.style.display='none'"/>
            <div class="filename">{{ introAudioFile.name }}</div>
        </div>
        <ng-container *ngIf="introAudioFile; else normalView">Drag and drop audio file here to change intro</ng-container>
        <ng-template #normalView>Drag and drop files here</ng-template>
    </div>
    <input type="file" accept=".mp3, .wav" #fileInput style="display: none;" (change)="onFileInputChange($event, 'intro')">

    <!-- Pdf file drag and drop zone/ textarea -->
    <ng-container *ngIf="submissionForm2 === false; else outputResult">
    <label formArrayName="files">PDF</label>
    <div class="drop-zone-pdf" (drop)="onDrop($event, 'pdf')" (dragover)="onDragOver($event)" (click)="pdfFileInput.click()">
        <div *ngIf="pdfFile">
            <img src="assets/icons/pdfIcon.png" class="file-icon-image" onerror="this.style.display='none'"/>

            <ng-container *ngIf="pageNoCount > 10; else normalView;">
              <div class="validationError filename">{{ pdfFile.name }}</div>
              <div *ngIf="pageNoCount > 10" class="validationError error-message">The PDF has more than 10 pages</div>
            </ng-container>
            <ng-template #normalView>
                <div class="filename padding-bottom">{{ pdfFile.name }}</div>
            </ng-template>

        </div>
        <ng-container *ngIf="pdfFile; else another">
          <div>Drag and drop here to change your PDF file</div>
          <div>(max 10 pages)</div>
        </ng-container>
        <ng-template #another>
          <div>Please drag and drop your PDF file here</div>
          <div>(max 10 pages)</div>
        </ng-template>
    </div>
    <input type="file" accept=".pdf" #pdfFileInput style="display: none;" (change)="onFileInputChange($event, 'pdf')">
    </ng-container>
    <ng-template #outputResult>
        <div class="container">
            <div class="box-1">
                <label>Original Text</label>
                <textarea rows="8" #box (keyup)="onTokenChange(box.value)" type="text" matInput [(ngModel)]="onlyPdfFileToTextOutput" [ngModelOptions]="{standalone: true}"></textarea>
                <div class="token-info">Used Tokens: {{withinTokenLimit}}/ {{tokenLimit}}</div>
            </div>
            <div class="box-2">
                <label>Podcast Script</label>
                <textarea rows="8" type="text" id="myInput" matInput [(ngModel)]="pdfFileTextOutput" [ngModelOptions]="{standalone: true}"></textarea>
                <div class="token-info">Remaining Tokens: {{tokenLimit - withinTokenLimit}}</div>
            </div>
        </div>
    </ng-template>

    <!-- Outro drag and drop zone -->
    <label formArrayName="files">Outro</label>
    <div class="drop-zone" (drop)="onDrop($event, 'outro')" (dragover)="onDragOver($event)" (click)="fileInput2.click()">
        <div *ngIf="outroAudioFile">
            <img src="assets/icons/audioFileIcon.png" class="file-icon-image" onerror="this.style.display='none'"/>
            <div class="filename">{{ outroAudioFile.name }}</div>
        </div>
        <ng-container *ngIf="outroAudioFile; else normalView">Drag and drop audio file here to change outro</ng-container>
        <ng-template #normalView>Please drag and drop your audio file here (MP3 or WAV format only).</ng-template>
    </div>
    <input type="file" accept=".mp3, .wav" #fileInput2 style="display: none;" (change)="onFileInputChange($event, 'outro')">

    <!-- Submit button at bottom -->
    <div class="page-end" fxLayout="row" fxLayoutAlign="end end">
        <ng-container *ngIf="submissionForm2 === true">
            <button class="hypecast-secondary-button flex-initial rounded-md h-12 mr-3" mat-raised-button type="button" (click)="regenerateTextSubmit()">
                Regnerate Podcast Script
                <mat-icon class="regenerate-spinner" *ngIf="loadingIndicatorPdfToTextRegeneration === true">
                    <mat-spinner color="accent" diameter="20">
                    </mat-spinner>
                </mat-icon>
            </button>
        </ng-container>

      <ng-container *ngIf="submissionForm2 === false">
        <hypecast-admin-buttons-submit [disabled]="!newsletterForm.valid || !pdfFile">
          Generate Podcast Script
          <mat-icon class="spinner" *ngIf="showLoadingIndicator === true  && completionPdfToText === false">
            <mat-spinner color="accent" diameter="20">
            </mat-spinner>
          </mat-icon>
        </hypecast-admin-buttons-submit>
      </ng-container>

      <ng-container *ngIf="submissionForm2 === true">
        <hypecast-admin-buttons-submit [disabled]="!newsletterForm.valid || !pdfFile">
          Generate Podcast
          <mat-icon class="spinner" *ngIf="showLoadingIndicator === true  && completionPdfToText === false">
            <mat-spinner color="accent" diameter="20">
            </mat-spinner>
          </mat-icon>
        </hypecast-admin-buttons-submit>
      </ng-container>
    </div>

</form>
