<h1 class="headline">Results</h1>
<div class="info" *ngIf="loaded">
  <h2 class="headline-2"><strong>Statistics</strong></h2>
  <p class="paragraph"><strong>Duration: </strong>{{ audioDuration | date:'mm:ss'}} minutes</p>
  <p class="paragraph"><strong>Processing time: </strong>{{processingTime | date:'mm:ss'}} minutes</p>
  <p class="paragraph"><strong>Factor: </strong>≈ {{factor | number:'1.0-0':'de' }}</p>
</div>
<h2 class="headline-2"><strong>File</strong></h2>
<audio controls id="audioPlayer">
  <source src={{url}} type="audio/mpeg"/>
</audio>

