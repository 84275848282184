import { AudioNewsletterFile } from "./AudioNewsletterFile";
import { AudioNewsletterText } from "./AudioNewsletterText";

export class AudioNewsletter {
  audioNewsletterTexts: AudioNewsletterText[];
  audioNewsletterFiles?: AudioNewsletterFile[];

  constructor(audioNewsletterTexts: AudioNewsletterText[], audioNewsletterFiles?: AudioNewsletterFile[]){
    this.audioNewsletterTexts = audioNewsletterTexts;
    this.audioNewsletterFiles = audioNewsletterFiles;
  }
}
