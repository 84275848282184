import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AudioNewsletter } from '../models/AudioNewsletter';

@Injectable({
  providedIn: 'root'
})
export class AudioNewsletterService {

  constructor(private http: HttpClient) {
  }

  create(audioNewsletter: AudioNewsletter): Observable<any> {
    return this.http.post(environment.serverUrl + "audio-newsletter/", audioNewsletter, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  uploadAudioFile(file: File): Observable<any> {

    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(environment.serverUrl + "audio-files/", formData);
  }

  pdf2Text(file: File): Observable<any> {

    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(environment.serverUrl + "pdf2text/", formData);
  }

  onlyPdf2Text(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(environment.serverUrl + "pdf2text/onlyText", formData, { responseType: 'json'});
  }

  generateScript(text: string): Observable<any> {
    return this.http.post(environment.serverUrl + "pdf2text/generateScript", {text});
  }
}
