<button [matTooltipDisabled]="tooltipDisabled === true" [matTooltip]="tooltipText"
        [disabled]="disabled === true || isLoading === true" [class.disabled]="disabled === true || isLoading === true"
        matRipple [matRippleDisabled]="disabled === true || isLoading === true" (click)="onClick.emit()"
        class="hypecast-primary-button group flex items-center rounded-md text-xl h-12">

  <div class="flex-initial font-bold">
    <ng-content></ng-content>
  </div>
  <div class="flex-initial ml-4" *ngIf="isLoading === true">
    <mat-spinner [diameter]="20"></mat-spinner>
  </div>
</button>
