import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AudioNewsletterService } from '../shared/services/audio-newsletter.service';
import { Observable, of, switchMap } from 'rxjs';
import { AudioNewsletterText } from '../shared/models/AudioNewsletterText';
import { AudioNewsletterFile } from '../shared/models/AudioNewsletterFile';
import { AudioNewsletter } from '../shared/models/AudioNewsletter';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GeneratedText } from '../shared/models/GeneratedText';
import { encode, isWithinTokenLimit } from 'gpt-tokenizer';

import * as pdfjs from 'pdfjs-dist';
// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

@Component({
  selector: 'app-newsletter-pdf-input',
  templateUrl: './newsletter-pdf-input.component.html',
  styleUrls: ['./newsletter-pdf-input.component.scss']
})
export class NewsletterPdfInputComponent {
  newsletterForm: FormGroup;
  showLoadingIndicator: boolean = false;

  introAudioFile: any;
  outroAudioFile: any;
  pdfFile: any = null;

  tokenLimit: number = 8000;
  pageNoCount: number = 0;

  withinTokenLimit: any;
  updatedText: string = "";

  pdfFileTextOutput: any;
  onlyPdfFileToTextOutput: any;
  completionPdfToText: boolean = false;
  loadingIndicatorPdfToTextRegeneration: boolean = false;
  submissionForm2: boolean = false;

  constructor(private fb: FormBuilder,
              private router: Router,
              private toaster: ToastrService,
              private audioNewsletterService: AudioNewsletterService) {
    this.newsletterForm = this.fb.group({
      inputFields: [],
      files: [null]
    });
  }

  onDrop(event: DragEvent, fieldId: string) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    this.handleFiles(files as FileList, fieldId);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  // handling files with type validation in drag and drop
  private handleFiles(files: FileList | null, fileFormat: string) {
    if (files && files.length > 0) {
      const file = files[0];

      switch (fileFormat) {
        case 'intro':
          if (file.type === 'audio/wav' || file.type === 'audio/mpeg') {
            this.introAudioFile = file;
          } else {
            this.toaster.error('Please upload a valid audio file!')
          }
          break;

        case 'outro':
          if (file.type === 'audio/wav' || file.type === 'audio/mpeg') {
            this.outroAudioFile = file;
          } else {
            this.toaster.error('Please upload a valid audio file!')
          }
          break;

        case 'pdf':
          if (file.type === 'application/pdf') {
            this.pdfFile = file;
            this.countPageNumbers(this.pdfFile);
          } else {
            this.toaster.error('Please upload a valid PDF file!')
          }
          break;

        default:
          break;
      }

    }
  }

  onFileInputChange(event: Event, fieldId: string) {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    this.handleFiles(files, fieldId);
  }

  onPdfSubmit() {
    // Initialize array to hold AudioNewsletterFile objects


    this.showLoadingIndicator = true;

    let requestObservable = of(null);

    requestObservable =  this.audioNewsletterService.pdf2Text(this.pdfFile);

    requestObservable.subscribe((pdfFileResponse: any) =>{
        this.newsletterForm.get('inputFields')?.setValue(pdfFileResponse.data)
        this.pdfFileTextOutput = pdfFileResponse.data;
        this.completionPdfToText = true;
        this.submissionForm2 = true;

        this.toaster.success('Successfully pdf file converted into text!', 'Conversion Done!');
      },
      error => {
        this.toaster.error('Could converted the pdf into text!', 'Error');
        this.showLoadingIndicator = false;
      });

    this.audioNewsletterService.onlyPdf2Text(this.pdfFile).subscribe(response => {
        this.onlyPdfFileToTextOutput = response.data;
        this.tokenCounter(response.data);
      },
      error => {
        console.error("Only pdftoText not possible: ", error)
      })
  }

  onFinalSubmit(){
    this.showLoadingIndicator = true;
    this.completionPdfToText = false;

    // Initialize arrays to hold AudioNewsletterText and AudioNewsletterFile objects
    let audioNewsletterTexts: AudioNewsletterText[] = [];
    let audioNewsletterFiles: AudioNewsletterFile[] = [];

    const newsletterText = new AudioNewsletterText("converted text: ",this.pdfFileTextOutput, "male")
    audioNewsletterTexts.push(newsletterText);


    let requestObservable: Observable<any | null> = of(null);

    if (this.introAudioFile && this.outroAudioFile) {
      requestObservable = this.audioNewsletterService.uploadAudioFile(this.introAudioFile).pipe(
        switchMap(response => {
          // After uploading intro audio, store its media ID in the array
          audioNewsletterFiles.push(new AudioNewsletterFile('intro', response.data));
          // Proceed to upload the outro audio file
          return this.audioNewsletterService.uploadAudioFile(this.outroAudioFile);
        }),
        switchMap(response => {
          // After uploading outro audio, store its media ID in the array
          audioNewsletterFiles.push(new AudioNewsletterFile('outro', response.data));
          // Create an AudioNewsletter object with the gathered data
          const audioNewsletter = new AudioNewsletter(audioNewsletterTexts, audioNewsletterFiles);
          // Continue to create the audio newsletter
          return this.audioNewsletterService.create(audioNewsletter);
        })
      )
    }
    else if (this.introAudioFile) {
      requestObservable = this.audioNewsletterService.uploadAudioFile(this.introAudioFile).pipe(
        switchMap(response => {
          // After uploading intro audio, store its media ID in the array
          audioNewsletterFiles.push(new AudioNewsletterFile('intro', response.data));

          const audioNewsletter = new AudioNewsletter(audioNewsletterTexts, audioNewsletterFiles);

          // Proceed to upload the outro audio file
          return this.outroAudioFile? this.audioNewsletterService.uploadAudioFile(this.outroAudioFile): this.audioNewsletterService.create(audioNewsletter);
        }))
    }
    else if (this.outroAudioFile) {
      requestObservable = this.audioNewsletterService.uploadAudioFile(this.outroAudioFile).pipe(
        switchMap(response => {
          // After uploading outro audio, store its media ID in the array
          audioNewsletterFiles.push(new AudioNewsletterFile('outro', response.data));

          const audioNewsletter = new AudioNewsletter(audioNewsletterTexts, audioNewsletterFiles);

          // Proceed to create the audio newsletter
          return this.audioNewsletterService.create(audioNewsletter);
        })
      )
    }
    else {
      const audioNewsletter = new AudioNewsletter(audioNewsletterTexts, audioNewsletterFiles);
      requestObservable = this.audioNewsletterService.create(audioNewsletter)
    }

    requestObservable.subscribe(
      response => {
        this.router.navigate(['/audio-player'], {
          queryParams: {
            url: encodeURI(response.data.url),
            processingTime: response.data.processingTime,
          }
        });
        this.toaster.success('Text converted to audio successfully!', 'Great!');
      },
      error => {
        this.toaster.error('Please submit a valid form');
        this.showLoadingIndicator = false;
      }
    );
  }

  tokenCounter(text: string){
    // Encode text into tokens
    const tokens = encode(text)

    // Check if text is within the token limit
    // returns false if the limit is exceeded, otherwise returns the actual number of tokens (truthy value)
    this.withinTokenLimit = isWithinTokenLimit(text, this.tokenLimit)
  }

  onTokenChange(text: string){
    // Encode text into tokens
    const tokens = encode(text)

    // Check if text is within the token limit
    // returns false if the limit is exceeded, otherwise returns the actual number of tokens (truthy value)
    this.withinTokenLimit = isWithinTokenLimit(text, this.tokenLimit)

    this.updatedText = text;
  }

  regenerateTextSubmit() {
    let text;

    this.loadingIndicatorPdfToTextRegeneration = true;

    if(this.updatedText !== "") {
      text = new GeneratedText(this.updatedText)
    }
    else {
      text = new GeneratedText(this.pdfFileTextOutput);
    }

    let jsonString = JSON.stringify({ textValue: text });

    this.audioNewsletterService.generateScript(jsonString).subscribe(regeneratedTextResponse => {
        this.pdfFileTextOutput = regeneratedTextResponse.data;
        this.loadingIndicatorPdfToTextRegeneration = false;
      },
      error=>{
        this.toaster.error('Regeneration Failed');
        this.loadingIndicatorPdfToTextRegeneration = false;
      })
  }

  async countPageNumbers(file: any){
    const reader = new FileReader();

    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      const uint8Array = new Uint8Array(arrayBuffer);

      pdfjs.getDocument(uint8Array).promise.then(pdf => {
        this.pageNoCount = pdf.numPages;
        if(this.pageNoCount > 10){
          this.newsletterForm.setErrors({ 'invalidForm': true });
        } else {
          this.newsletterForm.setErrors(null);
        }
      })
        .catch(error => {
          console.error('Error counting pages: ', error);
        });
    }

    await reader.readAsArrayBuffer(file);
  }

}
