import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewsletterFormComponent} from "./newsletter-form/newsletter-form.component";
import {AudioPlayerComponent} from "./audio-player/audio-player.component";
import { SwitcherComponent } from './shared/components/switcher/switcher.component';

const routes: Routes = [
  { path: '', component: SwitcherComponent },
  { path: 'audio-player', component: AudioPlayerComponent,    data: { title: 'Results' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
