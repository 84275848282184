import { Component, Input } from '@angular/core';

import { BaseLoadingButton } from '../shared/base-button';

@Component({
  selector: 'hypecast-admin-buttons-submit',
  templateUrl: './buttons-submit.component.html',
  styleUrls: ['./buttons-submit.component.scss']
})
export class ButtonsSubmitComponent extends BaseLoadingButton {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() tooltipText: string = 'Submit';

  @Input() tooltipDisabled: boolean | undefined;
}
