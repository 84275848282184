import {NgModule} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NewsletterFormComponent} from './newsletter-form/newsletter-form.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule, MatIconRegistry} from "@angular/material/icon";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyInputModule} from "@angular/material/legacy-input";
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { SwitcherComponent } from './shared/components/switcher/switcher.component';
import { NewsletterPdfInputComponent } from './newsletter-pdf-input/newsletter-pdf-input.component';
import { ToastrModule } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {ButtonsCreateModule} from "./shared/components/buttons/create/buttons-create.module";
import {ButtonsSubmitModule} from "./shared/components/buttons/submit/buttons-submit.module";


@NgModule({
  declarations: [
    AppComponent,
    NewsletterFormComponent,
    AudioPlayerComponent,
    SwitcherComponent,
    NewsletterPdfInputComponent
  ],
  imports: [
    ButtonsCreateModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    // MatLegacyInputModule,
    ToastrModule.forRoot(),
    ButtonsSubmitModule
  ],
  providers: [],
  bootstrap: [AppComponent]

})

export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIconLiteral("remove", this.domSanitizer.bypassSecurityTrustHtml(REMOVE_ICON));
    this.matIconRegistry.addSvgIconLiteral("close", this.domSanitizer.bypassSecurityTrustHtml(CLOSE_ICON));
    this.matIconRegistry.addSvgIconLiteral("cancel", this.domSanitizer.bypassSecurityTrustHtml(CANCEL_ICON));
    this.matIconRegistry.addSvgIconLiteral("delete", this.domSanitizer.bypassSecurityTrustHtml(DELETE_ICON));
  }
}


const REMOVE_ICON = `<svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 2H8H6H0V0H6H8H14V2Z" fill="#090909"/></svg>
`;
const CLOSE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
`;
const CANCEL_ICON = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
`;
const DELETE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
`;
