import {Component, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AudioNewsletter} from "../shared/models/AudioNewsletter";
import {AudioNewsletterText} from '../shared/models/AudioNewsletterText';
import {AudioNewsletterService} from '../shared/services/audio-newsletter.service';
import {AudioNewsletterFile} from '../shared/models/AudioNewsletterFile';
import {switchMap} from "rxjs";
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss']
})
export class NewsletterFormComponent {
  @ViewChildren('textarea') textareas: QueryList<any> = new QueryList<any>;
  @ViewChildren('speakerDropdown') speakerDropdownElement!: QueryList<ElementRef>;



  initialInputFields = [''];


  showLoadingIndicator: boolean = false;
  newsletterForm: FormGroup;

  introAudioFile: any;
  outroAudioFile: any;

  constructor(private fb: FormBuilder,
              private router: Router,
              private toaster: ToastrService,
              private audioNewsletterService: AudioNewsletterService) {
    this.newsletterForm = this.fb.group({
      inputFields: this.fb.array([]),
      audioFiles: [null]
    });
    this.initializeInputFields();
  }

  get inputFields(): FormArray {
    return this.newsletterForm.get('inputFields') as FormArray;
  }

  ngOnInit() {

  }

  onDrop(event: DragEvent, fieldId: string) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    this.handleFiles(files as FileList, fieldId);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  // handling files with type validation in drag and drop
  handleFiles(files: FileList | null, fileFormat: string) {
    if (files && files.length > 0) {
      const file = files[0];

      switch (fileFormat) {
        case 'intro':
          if (file.type === 'audio/wav' || file.type === 'audio/mpeg') {
            this.introAudioFile = file;
          } else {
            this.toaster.error('Please upload a valid audio file!')
          }
          break;

        case 'outro':
          if (file.type === 'audio/wav' || file.type === 'audio/mpeg') {
            this.outroAudioFile = file;
          } else {
            this.toaster.error('Please upload a valid audio file!')
          }
          break;

        default:
          break;
      }
    }
  }

  onFileInputChange(event: Event, fieldId: string) {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    this.handleFiles(files, fieldId);
  }

  initializeInputFields(): void {
    const inputFieldsArray = this.newsletterForm.get('inputFields') as FormArray;
    this.initialInputFields.forEach((field, index) => {
      let newField = this.fb.control(field);
      if (index === 0) {
        newField = this.fb.control(field, Validators.required);
      }
      inputFieldsArray.push(newField);
    });
  }

  addInputField(): void {
    const newField = this.fb.control('');
    this.inputFields.push(newField);

    // focus/ putting cursor to the new textarea
    setTimeout(() => {
      this.textareas.last.nativeElement.focus()
    }, 0);
  }

  removeExtraNewlines(inputText: string) {
    return inputText.replace(/(\n\s*){2,}/g, '\n');
  }

  // copy & paste text in textarea
  handlePaste(event: ClipboardEvent): void {
   let inputFieldLength = this.inputFields.length;

    const enteredText  = event.clipboardData ;
    let pasteText = enteredText?.getData('text').trim();

    pasteText = this.removeExtraNewlines(pasteText!);

    if(inputFieldLength === 1){
      setTimeout(() => {
        let lines = pasteText!.split('\n');

        // Get the text before the first newline
        let firstLine = lines[0];
        let control = this.inputFields.at(0);

        control.setValue(firstLine.trim());
      }, 0);
    }

    let numberOfLineBreaks = (pasteText?.match(/\n/g)||[]).length;

    for(let i = 1; i <= numberOfLineBreaks; i++){
      if(numberOfLineBreaks > 0) {
        const newField = this.fb.control('');
        this.inputFields.push(newField);

        const tempText : string[] = [];
        const textAfterNewLine = pasteText?.split(new RegExp(`${tempText}\\n`))[i];

        // replace multiple newlines and spaces
        textAfterNewLine!.replace(/^\s*[\r\n]/g, "");

        if(inputFieldLength > 1 && i===1) {
          const currentInputFieldLength = inputFieldLength;

          setTimeout(() => {
            let lines = pasteText!.split('\n');

            // Get the text before the first newline
            let firstLine = lines[0];
            let control = this.inputFields.at(currentInputFieldLength-1);

            control.setValue(firstLine.trim());
          }, 0);
        }

        // initially inputFieldLength is 1. Then it increasing with the new field.
        const control = this.inputFields.at(inputFieldLength++);
        control.setValue(textAfterNewLine.trim());

        setTimeout(() => {
          this.textareas.last.nativeElement.focus()
        }, 0);

      }
    }
  }

  private auto_grow(element: any) {
    element.style.height = "20px";
    element.style.height = (element.scrollHeight) + "px";
  }

  private lineCounter(textArea: HTMLElement): number {
    let offsetHeight = textArea.offsetHeight;
    const computedStyle = window.getComputedStyle(textArea);
    const lineHeight = parseInt(computedStyle.getPropertyValue('line-height'));

    textArea.removeAttribute('style');

    let totalLines = Math.floor((offsetHeight) / lineHeight) - 1;

    return totalLines;
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    let textAreas = this.textareas.toArray();

    switch(event.key) {
      case "ArrowUp":
        // jumping to previous textarea
        if (textAreas.length !== null && index > 0) {
          const previousTextArea = textAreas[index - 1].nativeElement;
          const currentTextArea = textAreas[index].nativeElement;

          // storing defaultHeight
          const defaultHeightPixel = currentTextArea.style.height;
          const defaultHeight = parseInt(defaultHeightPixel);
          
          // to adjust the height of textarea
          this.auto_grow(currentTextArea)

          let lines = this.lineCounter(currentTextArea);
      
          currentTextArea.setAttribute('rows', 8);
          currentTextArea.setAttribute('cdkautosizeminrows', 8);

          const heightString = currentTextArea.style.height;
          const heightValue = parseInt(heightString);

          // comparing default height of textarea if the height is less than default height of textarea
          if(heightValue <= defaultHeight){
            currentTextArea.style.height = defaultHeightPixel
          }
          
          // textarea selection positions
          const previousSelectionStart = previousTextArea.selectionStart;
          const previousSelectionEnd = previousTextArea.selectionEnd;
          let currentAreaSelectionStart = currentTextArea.selectionStart;
          
          if (lines > 1 && currentAreaSelectionStart > 0) {
            // move the cursor to the beginning of the previous line
            // textSelectionStart - 1 and textSelectionEnd - 1 adjustments to move cursor to the previous line within the textarea
            setTimeout(() => {
              previousTextArea.setSelectionRange(
                previousSelectionStart - 1,
                previousSelectionEnd - 1
              );
            });
          } 
          else {
            // focus on the previous textarea
            previousTextArea.focus();

            // Delay the cursor positioning to ensure the textarea has received focus
            setTimeout(() => {
              previousTextArea.setSelectionRange(
                previousSelectionStart,
                previousSelectionStart
              );
            });
          }

      }
        break;
      case "ArrowDown":
        // jumping to the next textarea
        if (textAreas.length !== null && index < textAreas.length - 1) {
          const nextTextArea = textAreas[index + 1].nativeElement;
          const currentTextArea = textAreas[index].nativeElement;

          // storing defaultHeight
          const defaultHeightPixel = currentTextArea.style.height;
          const defaultHeight = parseInt(defaultHeightPixel);

          const nextSelectionStart = nextTextArea.selectionStart;
          const nextSelectionEnd = nextTextArea.selectionEnd;

          // to adjust the height of textarea
          this.auto_grow(currentTextArea);

          let lines = this.lineCounter(currentTextArea);

          currentTextArea.setAttribute('rows', 8);
          currentTextArea.setAttribute('cdkautosizeminrows', 8);

          const heightString = currentTextArea.style.height;
          const heightValue = parseInt(heightString);

          // comparing default height of textarea if the height is less than default height of textarea
          if (heightValue <= defaultHeight) {
            currentTextArea.style.height = defaultHeightPixel;
          }

          // current textarea selection positions
          const currentAreaSelectionStart = currentTextArea.selectionStart;

          if (lines > 1 && currentAreaSelectionStart < currentTextArea.value.length) {
            // move the cursor to the beginning of the next line
            setTimeout(() => {
              nextTextArea.setSelectionRange(
                nextSelectionStart + 1,
                nextSelectionEnd + 1
              );
            });
          } 
          else {
            // focus on the next textarea
            nextTextArea.focus();

            // Delay the cursor positioning to ensure the textarea has received focus
            setTimeout(() => {
              nextTextArea.setSelectionRange(
                nextSelectionStart,
                nextSelectionEnd
              );
            });
          }
      }
        break;
      case "Enter":
        if(!event.shiftKey){
        this.addInputField();
        event.preventDefault();

        // Get the total number of input fields
        const totalInputFields = this.inputFields.length;

        // Check if Enter is pressed in any input field except the last one
        if (index < totalInputFields - 1) {
          setTimeout(() => {
            // array of all current textareas
            const textAreaArray = this.textareas.toArray();

            // Get the current textarea and its properties
            const currentTextArea = textAreaArray[index].nativeElement;
            const selectionStart = currentTextArea.selectionStart;
            const text = currentTextArea.value;

            // Extract text before and after the cursor
            const textBeforeCursor = text.substring(0, selectionStart);
            const textAfterCursor = text.substring(selectionStart);
            const wordsAfterCursor = textAfterCursor.split(/\s+/);

            const textFromWords = wordsAfterCursor.join(' ');

            // Create a new array with values from all textareas
            let newArray = textAreaArray.map(el => el.nativeElement.value);

            // Update the current and next textareas in the new array
            newArray[index] = textBeforeCursor;
            // Insert the textFromWords at the specified index (index + 1) in newArray
            newArray.splice(index + 1, 0, textFromWords);

            // Update the values of all textareas with the values from the new array
            for (let i = 0; i < textAreaArray.length; i++) {
              textAreaArray[i].nativeElement.value = newArray[i];
            }

            // Get the next textarea in the array
            const nextTextAreaArray = textAreaArray[index + 1];

            // Check if there is a next textarea
            if (nextTextAreaArray) {
              // Get the native element of the next textarea
              const nextTextArea = nextTextAreaArray.nativeElement;
              nextTextArea.focus();
            }
          });
        }
      }
        break;
      case 'Backspace':
        // deleting text area key stroking backspace
        // hitting backspace from the starting position of textarea without removing text
        if(this.getTextareaValue(index).trim() === '') {

          // Prevents the default behavior of the Backspace key
          event.preventDefault();

          // used toArray()[] to perform array operations because the textareas is a QueryList
          if(index > 0) {
            this.textareas.toArray()[index-1].nativeElement.focus();
          }

          this.removeInputField(index);
        }
        else {
          const currentTextArea = this.textareas.toArray()[index].nativeElement;
          const selectionStart = currentTextArea.selectionStart;
          const selectionEnd = currentTextArea.selectionEnd;

          if (selectionStart === 0 && selectionEnd === currentTextArea.value.length) {
            // If the entire text is selected, just remove the selected text
            currentTextArea.value = '';
          }
          else if (selectionStart === 0) {
            const currentTextArea = this.textareas.toArray()[index-1].nativeElement;

            // If the cursor is at the beginning, merge with the previous textarea
            const textInDeletedField = this.getTextareaValue(index);

            const lastCharacterOfPrevious = textInDeletedField.slice(-1);
            currentTextArea.value += textInDeletedField + lastCharacterOfPrevious;
            currentTextArea.focus();
            this.removeInputField(index);
          }
        }
        break;
      default:
        break;
    }
  }

  private getTextareaValue(index: number): string {
    return this.textareas.toArray()[index].nativeElement.value;
  }

  removeInputField(index: number): void {
    if(index > 0){
      this.inputFields.removeAt(index);
    }
  }

  onSpeakerChange(event: Event) {
    const selectedVoice = (event.target as HTMLSelectElement).value;

    // Toggle between 'male' and 'female' based on the current value
    selectedVoice === 'male' ? 'male':'female';
  }

  onSubmit() {
    this.showLoadingIndicator = true;

    let audioNewsletterTexts: AudioNewsletterText[] = [];
    let audioNewsletterFiles: AudioNewsletterFile[] = [];

    let speakers: string[] =[];

    this.speakerDropdownElement.forEach((element: ElementRef) => {

      // Get the value of speaker for every textarea from dropdown
      const selectedValue = (element.nativeElement as HTMLSelectElement).value;

      speakers.push(selectedValue)
    });

    // Loop through textareas QueryList and create AudioNewsletterText objects
    this.textareas.forEach((item,index) => {
      const title = `paragraph_${index + 1}`;
      const audioNewsletterText = new AudioNewsletterText(title, item.nativeElement.value, speakers[index]);
      audioNewsletterTexts.push(audioNewsletterText);
    });

    let requestObservable;
    if (this.introAudioFile && this.outroAudioFile) {
      requestObservable = this.audioNewsletterService.uploadAudioFile(this.introAudioFile).pipe(
        switchMap(response => {
          // After uploading intro audio, store its media ID in the array
          audioNewsletterFiles.push(new AudioNewsletterFile('intro', response.data));
          // Proceed to upload the outro audio file
          return this.audioNewsletterService.uploadAudioFile(this.outroAudioFile);
        }),
        switchMap(response => {
          // After uploading outro audio, store its media ID in the array
          audioNewsletterFiles.push(new AudioNewsletterFile('outro', response.data));
          // Create an AudioNewsletter object with the gathered data
          const audioNewsletter = new AudioNewsletter(audioNewsletterTexts, audioNewsletterFiles);
          // Continue to create the audio newsletter
          return this.audioNewsletterService.create(audioNewsletter);
        })
      )
    }
    else if (this.introAudioFile) {
      requestObservable = this.audioNewsletterService.uploadAudioFile(this.introAudioFile).pipe(
        switchMap(response => {
          // After uploading intro audio, store its media ID in the array
          audioNewsletterFiles.push(new AudioNewsletterFile('intro', response.data));

          const audioNewsletter = new AudioNewsletter(audioNewsletterTexts, audioNewsletterFiles);

          // Proceed to upload the outro audio file
          return this.outroAudioFile? this.audioNewsletterService.uploadAudioFile(this.outroAudioFile): this.audioNewsletterService.create(audioNewsletter);
        }))
    }
    else if (this.outroAudioFile) {
      requestObservable = this.audioNewsletterService.uploadAudioFile(this.outroAudioFile).pipe(
        switchMap(response => {
          // After uploading outro audio, store its media ID in the array
          audioNewsletterFiles.push(new AudioNewsletterFile('outro', response.data));

          const audioNewsletter = new AudioNewsletter(audioNewsletterTexts, audioNewsletterFiles);

          // Proceed to create the audio newsletter
          return this.audioNewsletterService.create(audioNewsletter);
        })
      )
    }
    else {
      const audioNewsletter = new AudioNewsletter(audioNewsletterTexts, audioNewsletterFiles);
      requestObservable = this.audioNewsletterService.create(audioNewsletter)
    }

    requestObservable.subscribe(
      response => {
        this.router.navigate(['/audio-player'], {
          queryParams: {
            url: encodeURI(response.data.url),
            processingTime: response.data.processingTime,
          }
        });
        this.toaster.success('Text converted to audio successfully!', 'Success');
      },
      error => {
        this.toaster.error('An unknown error has occurred', 'Error')
        this.showLoadingIndicator = false;
      }
    );
  }

  private countTotalWords(inputValues: string[]): void {
    let totalWords = 0;

    inputValues.forEach(value => {
      const words = value.trim().split(/\s+/);
    });

  }
}
