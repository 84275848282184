import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export abstract class BaseButton {
  @Input() text: string | undefined;
}

@Directive()
export abstract class BaseRouterButton extends BaseButton {
  @Input() routerLink: string | undefined;
}

@Directive()
export abstract class BaseActionButton extends BaseButton {
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() class: string | undefined;
}

@Directive()
export abstract class BaseLoadingButton extends BaseActionButton {
  @Input() isLoading: boolean | undefined;
  @Input() disabled: boolean | undefined;
}
