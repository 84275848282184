import { NgModule } from '@angular/core';
import { ButtonsSubmitComponent } from './buttons-submit.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  imports: [
    MatProgressSpinnerModule,
    MatRippleModule,
    MatTooltipModule
  ],
  declarations: [ButtonsSubmitComponent],
  exports: [ButtonsSubmitComponent]
})
export class ButtonsSubmitModule { }
