import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {registerLocaleData} from "@angular/common";
import de from '@angular/common/locales/de';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {
  public url: string | undefined;
  public processingTime: Date | undefined;
  public audioDuration: Date | undefined;
  public factor: number | undefined;
  public loaded: boolean = false;
  private test: number | undefined;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    registerLocaleData(de);

    this.activatedRoute.queryParams.subscribe((params) => {
      this.url = params['url'];
      this.processingTime = new Date(0, 0, 0, 0, 0, 0);
      this.test = +params['processingTime'];
      this.processingTime.setMilliseconds(+params['processingTime']);
    });

    let audioElement = document.getElementById('audioPlayer') as HTMLAudioElement;

    audioElement.addEventListener('loadedmetadata', () => {
      this.loaded = true;
      this.audioDuration = new Date(0, 0, 0, 0, 0, 0);
      this.audioDuration!.setMilliseconds(audioElement.duration * 1000);
      this.factor =  (audioElement.duration  * 1000) / this.test!;
    });


  }

}
