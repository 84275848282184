import { AudioQuote } from "./AudioQuote";

export class AudioNewsletterText {
    title: string;
    text: string;
    voice: string
    audioQuote?: AudioQuote;

    constructor (title: string, text: string, voice: string, audioQuote?: AudioQuote) {
        this.title = title;
        this.text = text;
        this.voice = voice; 
        this.audioQuote = audioQuote;
    }
}
